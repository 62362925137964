

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ProfileModal extends Vue {
    @Prop({ default: false }) show!: boolean;

    profile = {};
    saving = false;
    avatar = '';

    get showModal() {
        return this.show;
    }

    get profileData() {
        return this.$store.state.user.profile;
    }

    _initProfile() {
        if (this.profileData) {
            Object.assign(this.profile, this.profileData);
        }
    }

    handleClose() {
        this.avatar = '';
        this.$emit('toggle');
    }

    handleSave() {
        this.saving = true;
        this.$store.dispatch(
            'updateProfile',
            {
                id: this.profileData._id,
                profile: this.profile,
                avatar: this.avatar
            }
        ).then(() => {
            this.$store.dispatch('snackbar/pushMessage', { message: 'Saved Profile!' });
            this.handleClose();
        })
        .catch((error: any) => {
            this.$store.dispatch('snackbar/pushError', { message: `There was an error: ${error.message}` });
        })
        .finally(() => {
            this.saving = false;
        });
    }

    mounted() {
        if (this.show) {
            this._initProfile();
        }
    }

    @Watch('show')
    onShow(val: boolean, oldVal: boolean) {
        if (val) {
            this._initProfile();
        }
    }
}
