











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import BackButton from '@/components/ui/BackButton.vue';
import ProfileModal from '@/components/ui/ProfileModal.vue';
import PasswordModal from '@/components/ui/PasswordModal.vue';
import { KnoxService } from '@/services/knox.service';

@Component({
  components: {
    BackButton,
    ProfileModal,
    PasswordModal
  },
  computed: {
    ...mapState(['user'])
  }
})
export default class Settings extends Vue {
  editProfile = false;
  updatePassword = false;
  loading = false;
  user: any;

  async handleUpdateSubscription() {
    // call knox service
    this.loading = true;
    const data = await KnoxService.createPortalSession(this.user.profile.stripeCustomer);
    this.loading = false;

    
    window.location.href = data.url;
  }

  get editing() {
    return this.$route.query['edit']
  }

  handleBack() {
    if (this.editing) {
      this.$router.push({ name: 'home' });
    } else {
      this.$router.go(-1);
    }
  }

  mounted() {
    if (this.editing) {
      this.editProfile = true;
    }
  }
}
