import { render, staticRenderFns } from "./Settings.vue?vue&type=template&id=c4e8332a&scoped=true&"
import script from "./Settings.vue?vue&type=script&lang=ts&"
export * from "./Settings.vue?vue&type=script&lang=ts&"
import style0 from "./Settings.vue?vue&type=style&index=0&id=c4e8332a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4e8332a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAvatar,VBtn,VImg,VProgressCircular})
